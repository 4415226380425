import {
  Box,
  Button,
  Grid,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import ChatPromtPagination from "components/ChatPromtPagination";
import Card from "components/card/Card.js";
import { userPrivateRequest } from "config/axios.config";
import { AuthContext } from "contexts/AuthContext";
import { ConfigContext } from "contexts/ConfigContext";
import moment from "moment/moment";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CircularProgress from "components/charts/CircularProgress";
import WithdrawModal from "./WithdrawModal";

export default function StakingReward({
  user,
  stakeInformation,
  fetchStakeInformation,
}) {
  const lang = useLocation();
  const configData = useContext(ConfigContext);
  const authData = useContext(AuthContext);
  const config = configData.config || {};
  const isKor = lang?.pathname.includes("/ko");
  const { t } = useTranslation();
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const limit = 10;
  const [page, setPage] = useState(1);
  const [totalRow, setTotalRow] = useState(0);
  const [rewards, setRewards] = useState([]);

  const colors = config?.global?.COLORS?.value ?? {};
  const primaryColor = useColorModeValue(
    colors?.primaryColor ? colors?.primaryColor : "navy.700",
    colors?.primaryDarkColor ? colors?.primaryDarkColor : "white"
  );
  const primaryHoverColor = useColorModeValue(
    colors?.primaryHoverColor ? colors?.primaryHoverColor : "navy.700",
    colors?.primaryDarkHoverColor ? colors?.primaryDarkHoverColor : "white"
  );

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");

  const [timeLeft, setTimeLeft] = useState(24 * 60 * 60); // 24 hours in seconds

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = moment.utc();
      const rewardTime = moment.utc(stakeInformation.nextRewardAt);
      const difference = rewardTime.diff(now);

      if (difference > 0) {
        setTimeLeft(difference);
      } else {
        setTimeLeft(0); // Reward time has passed
      }
    };

    // Initial calculation
    calculateTimeLeft();

    // Update every second
    const intervalId = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [stakeInformation.nextRewardAt]);

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hrs = Math.floor(totalSeconds / 3600);
    const mins = Math.floor((totalSeconds % 3600) / 60);
    const secs = totalSeconds % 60;
    return `${hrs.toString().padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };
  let creditLabel =
    config?.global?.CREDIT_CONFIGURATION?.value?.creditLabel ?? "CHI";
  const SI = config?.global?.STAKING_INFORMATION?.value;
  const rewardHistory = isKor ? SI?.rewardHistoryKr : SI?.rewardHistory;
  const withdraw = isKor ? SI?.unstakeKr : SI?.unstake;

  const fetchRewardHistories = async () => {
    const result = await userPrivateRequest
      .get(`/staking/rewards?status=pending&limit=${limit}&page=${page}`)
      .then((response) => {
        setRewards(response?.data?.data?.docs ?? []);
        setTotalRow(response?.data?.data?.totalDocs ?? 0);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  useEffect(() => {
    fetchRewardHistories();
  }, [page]);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Card mt={"20px"}>
        <Flex flexDirection={"row"} justifyContent={"space-between"}>
          <Text
            fontSize="xl"
            color={textColorPrimary}
            mb="20px"
            ms="10px"
            fontWeight="bold"
          >
            {rewardHistory}
          </Text>

          <Tooltip
            label={
              stakeInformation?.hasPendingWithdraw
                ? "A withdrawal is being processed. All withdrawals require 48 business hours for security and verification purposes."
                : ""
            }
            shouldWrapChildren
          >
            <Button
              variant="brand"
              w="183px"
              fontSize="sm"
              fontWeight="500"
              bg={primaryColor}
              _hover={{
                bg: primaryHoverColor,
              }}
              _active={{
                bg: primaryHoverColor,
              }}
              _focus={{
                bg: primaryHoverColor,
              }}
              onClick={() => {
                onOpen();
              }}
              _disabled={{
                bg: "#ececec",
                cursor: "not-allowed",
              }}
              disabled={stakeInformation?.hasPendingWithdraw}
            >
              {/* {withdraw} */}
              {t("Withdrawal")}
            </Button>
          </Tooltip>
        </Flex>
        <>
          <Grid
            bg={cardColor}
            p="15px"
            px="10px"
            mt="auto"
            mb="10px"
            templateColumns="repeat(3, 1fr)"
            gap={2}
          >
            <Card direction="column" py="5px" textAlign={"center"}>
              <Text
                fontSize="md"
                color="secondaryGray.600"
                fontWeight="700"
                mb="5px"
              >
                Deposit Amount
              </Text>
              <Text fontSize="lg" color={textColor} fontWeight="700">
                {Number(stakeInformation?.totalStaked ?? 0)}{" "}
                {process.env.REACT_APP_PLATFORM === "Questron"
                  ? "ACE"
                  : "DAPPX"}
              </Text>
            </Card>

            <Card direction="column" py="5px" textAlign={"center"}>
              <Text
                fontSize="md"
                color="secondaryGray.600"
                fontWeight="700"
                mb="3px"
              >
                Current Cycle
              </Text>
              <Box w="60px" mx="auto" mb="10px" mt="10px">
                <CircularProgress
                  percentage={stakeInformation?.currentCycleProgress}
                />
              </Box>
            </Card>

            <Card direction="column" py="5px" textAlign={"center"}>
              <Text
                fontSize="md"
                color="secondaryGray.600"
                fontWeight="700"
                mb="5px"
              >
                Total Reward
              </Text>
              <Text fontSize="lg" color={textColor} fontWeight="700">
                {Number(stakeInformation?.rewardData?.totalReward ?? 0)}{" "}
                {process.env.REACT_APP_PLATFORM === "Questron"
                  ? "ACE"
                  : "DAPPX"}
              </Text>
              <Text fontSize="sm" color={"secondaryGray.600"} fontWeight="500">
                Reward Countdown: <b>{formatTime(timeLeft)}</b>
              </Text>
            </Card>
          </Grid>

          {/* Table for Staking History */}
          <Flex direction="column" mb="25px">
            <Box overflowX="auto">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th
                      textAlign="center"
                      style={{ textTransform: "none" }}
                      fontSize="md"
                    >
                      {capitalizeFirstLetter(t("Date"))}
                    </Th>
                    <Th
                      textAlign="center"
                      style={{ textTransform: "none" }}
                      fontSize="md"
                    >
                      {capitalizeFirstLetter(t("Note"))}
                    </Th>
                    <Th
                      textAlign="center"
                      style={{ textTransform: "none" }}
                      fontSize="md"
                    >
                      {capitalizeFirstLetter(t("Amount"))}
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {rewards?.map((reward, index) => (
                    <Tr key={index}>
                      <Td textAlign="center" style={{ fontSize: "small" }}>
                        {moment(reward.createdAt).format("YYYY/MM/DD HH:mm")}
                      </Td>
                      <Td textAlign="center" style={{ fontSize: "small" }}>
                        {reward?.note}
                      </Td>
                      <Td textAlign="center" style={{ fontSize: "small" }}>
                        {Number(reward?.amount).toLocaleString()}{" "}
                        {process.env.REACT_APP_PLATFORM === "Questron"
                          ? "ACE"
                          : "DAPPX"}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              <ChatPromtPagination
                limit={limit}
                page={page}
                totalRow={totalRow}
                handlePageChange={(limit, newOffset, page) => {
                  setPage(page);
                }}
              />

              <WithdrawModal
                user={user}
                stakeInformation={stakeInformation}
                fetchStakeInformation={fetchStakeInformation}
                onClose={onClose}
                onOpen={onOpen}
                isOpen={isOpen}
              />
            </Box>
          </Flex>
        </>
      </Card>
    </>
  );
}
