import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  useColorModeValue,
  Spinner,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Heading,
  Box,
  Icon,
  Flex,
  Link,
  Radio,
  Stack,
  VStack,
  RadioGroup,
} from "@chakra-ui/react";
import { CiCircleCheck, CiCircleRemove } from "react-icons/ci";
import { useLocation } from "react-router-dom";
import useCustomHistory from "langHoc/useCustomHistory";
import { useTranslation } from "react-i18next";
import { AuthContext } from "contexts/AuthContext";
import { ConfigContext } from "contexts/ConfigContext";
import aceTokenABI from "utils/aceAbi.json";
import Pusher from "pusher-js";
import { BrowserProvider, Contract, parseUnits } from "ethers";
import Cookies from "js-cookie";
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";
import { userPrivateRequest } from "config/axios.config";
import { walletAddressFormat } from "utils/utils";
import { toast } from "react-toastify";

function DepositButton({ metrics, type, buttonHide = false }) {
  const { authState, depositModal, setDepositModal, profileFetch } =
    useContext(AuthContext);

  const [confirmationModal, setConfirmationModal] = useState(false);
  const configData = useContext(ConfigContext);
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [loading, setLoading] = useState(false);
  const history = useCustomHistory();
  const { t } = useTranslation();
  const lang = useLocation();
  const isKor = lang?.pathname.includes("/ko");

  const [walletBalance, setWalletBalance] = useState(0);
  const [pendingHash, setPendingHash] = useState("");
  const [successData, setSuccessData] = useState();
  const [amount, setAmount] = useState();

  // const [config, setConfig] = useState(
  //   configData?.config?.global?.WEB3_STAKING_CONFIGURATION?.value ?? {}
  // );
  const [buttonColor, setButtonColor] = useState("#834af4");
  const [buttonHoverColor, setButtonHoverColor] = useState("#834af4");

  useEffect(() => {
    const platform = process.env.REACT_APP_PLATFORM;
    if (platform === "Chingu") {
      setButtonColor("#834af4");
      setButtonHoverColor("#834af4");
    } else if (platform === "Questron") {
      setButtonColor("#09D809");
      setButtonHoverColor("#08a108");
    } else if (platform === "DappStoreAi") {
      setButtonColor("#0060ff");
      setButtonHoverColor("#02A3EA");
    }
  }, []);

  const replacer = (key, value) =>
    typeof value === "bigint" ? value.toString() : value;

  const encryptTransaction = (data) => {
    let secretKey = Cookies.get("token");
    return AES.encrypt(JSON.stringify(data, replacer), secretKey).toString();
  };

  const platform = process.env?.REACT_APP_PLATFORM;

  const getContractInfo = async () => {
    if (window.ethereum) {
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const tokenAddress =
        platform === "Questron"
          ? process.env.REACT_APP_ACE_CONTRACT_ADDRESS
          : process.env.REACT_APP_DAPPX_CONTRACT_ADDRESS;
      let ethersProvider = new BrowserProvider(window.ethereum);
      let signer = await ethersProvider.getSigner();
      let abi = aceTokenABI.abi;
      if (platform !== "Questron") {
      }
      let contract = new Contract(tokenAddress, abi, signer);
      const accounts = await window.ethereum
        .request({
          method: "eth_requestAccounts",
        })
        .then((response) => {
          return response;
        })
        .catch((response) => {
          console.log("catch block: ", response.message);
        });
      let account = accounts[0] || null;
      let balance = await contract.balanceOf(account);
      let balanceInDecimal = Number(balance) / Math.pow(10, 18);
      setWalletBalance(balanceInDecimal);
    }
  };
  const createTransaction = async () => {
    setLoading(true);
    const hasPendingWallet = await userPrivateRequest
      .get("/wallet/checkPendingDeposit")
      .then((response) => response?.data?.hasPendingDeposit)
      .catch(() => "no");

    if (hasPendingWallet === "yes") {
      toast("You have a transaction in progress", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
      return;
    }

    let amount =
      configData?.config?.global?.WEB3_STAKING_CONFIGURATION?.value
        ?.stakingAmount || 0;
    if (depositType == "pledging") {
      amount =
        configData?.config?.global?.WEB3_STAKING_CONFIGURATION?.value
          ?.pledgingAmount || 0;
    }

    const address = process.env?.REACT_APP_ADMIN_ADDRESS ?? "";
    if (!address) {
      toast(`Invalid admin wallet address`, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
      return;
    } else if (walletBalance < amount) {
      toast(
        platform === "Questron"
          ? "Insufficient ACE funds"
          : "Insufficient DAPPX funds",
        {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      setLoading(false);
      return;
    }

    if (window.ethereum) {
      const tokenAddress =
        platform === "Questron"
          ? process.env.REACT_APP_ACE_CONTRACT_ADDRESS
          : process.env.REACT_APP_DAPPX_CONTRACT_ADDRESS;
      const ethersProvider = new BrowserProvider(window.ethereum);
      const signer = await ethersProvider.getSigner();

      const activeAccount = await signer.getAddress();

      let userWallet = authState?.user?.metamaskAddress?.toLocaleLowerCase();
      if (activeAccount.toLocaleLowerCase() !== userWallet) {
        toast(
          `Please switch your MetaMask account to ${walletAddressFormat(
            userWallet
          )} to execute transaction`,
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        setLoading(false);
        return;
      }

      const contract = new Contract(tokenAddress, aceTokenABI.abi, signer);
      const value = parseUnits(amount.toString(), 18);
      const transaction = await contract
        .transfer(address, value)
        .catch((err) => {
          console.error("Error getting", err.message);
          setLoading(false);
          return null;
        });

      if (!transaction) return;

      setPendingHash(transaction.hash);
      const transactionData = {
        from: transaction.from,
        gasLimit: transaction.gasLimit,
        gasPrice: transaction.gasPrice,
        hash: transaction.hash,
        maxFeePerGas: transaction.maxFeePerGas,
        maxPriorityFeePerGas: transaction.maxPriorityFeePerGas,
        nonce: transaction.nonce,
        value: transaction.value,
        to: address,
        tokenAmountInDecimal: amount,
        depositType,
        currency: platform === "Questron" ? "ACE" : "DAPPX",
      };

      const payload = encryptTransaction(transactionData);
      const path =
        platform === "Questron" ? "/wallet/depositACE" : "wallet/depositDAPPX";
      userPrivateRequest.post(path, { payload }).finally(() => {
        setLoading(false);
        setDepositModal(false);
      });
    } else {
      setDepositModal(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (pendingHash) {
      setConfirmationModal(true);
      setDepositModal(false);
      const listeningEvent = `wallet-transaction-status-${pendingHash}`;
      const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
        cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      });
      const channel = pusher.subscribe(`wallet-transaction-${pendingHash}`);
      channel.bind(listeningEvent, (data) => {
        setSuccessData(data);
        if (data?.status === "confirmed") profileFetch();
      });

      return () => {
        channel.unbind_all();
        channel.unsubscribe();
      };
    }
  }, [pendingHash]);

  const stakingConfig =
    configData?.config?.global?.WEB3_STAKING_CONFIGURATION?.value;
  const modalConfig =
    configData?.config?.global?.WEB3_STAKING_MODAL_CONFIGURATION?.value;
  const title = isKor ? modalConfig?.titleKr : modalConfig?.title;
  const pledgingDescription = isKor
    ? modalConfig?.descriptionKr
    : modalConfig?.description;
  const stakingDescription = isKor
    ? modalConfig?.stakingDescriptionKr
    : modalConfig?.stakingDescription;

  const pledgingBulletPoints = isKor
    ? modalConfig?.bulletPointsKr
    : modalConfig?.bulletPoints;

  const stakingBulletPoints = isKor
    ? modalConfig?.stakingBulletPointsKr
    : modalConfig?.stakingBulletPoints;

  const pledgingReceivingLabel = isKor
    ? modalConfig?.receivingLabelKr
    : modalConfig?.receivingLabel;
  const stakingReceivingLabel = isKor
    ? modalConfig?.stakingReceivingLabelKr
    : modalConfig?.stakingReceivingLabel;

  const confirmedModalClose = () => {
    setConfirmationModal(false);
    setPendingHash(null);
  };
  const [depositType, setDepositType] = useState("pledging");

  console.log("Deposit type: " + depositType);

  return (
    <>
      <Modal
        isOpen={confirmationModal}
        onClose={
          successData?.status !== "confirmed"
            ? () => {}
            : () => confirmedModalClose()
        }
        isCentered
        width="800px"
        maxWidth="800px"
        className="deposit chakra-modal__content-container"
        id="deposit chakra-modal__content-container"
      >
        <ModalOverlay />
        <ModalContent
          backgroundColor="white"
          borderRadius="20px"
          width="400px"
          maxWidth="600px"
          height={"350px"}
        >
          <ModalBody p={"16px"} borderRadius={"16px"} width="400px">
            <Flex
              direction="column"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              {successData?.status !== "confirmed" &&
                successData?.status !== "failed" && (
                  <>
                    <Spinner w={20} h={20} mb="25px" color="#09d809" />
                    <Text
                      fontSize="lg"
                      mb="20px"
                      fontWeight="bold"
                      textAlign="center"
                    >
                      {modalConfig?.loadingPaymentTitle}
                      {/*                     
                    {amount}{" "}
                      {platform === "Questron" ? "ACE" : "DAPPX"} */}
                    </Text>
                    <Text
                      fontSize="sm"
                      mb="20px"
                      color="gray.500"
                      textAlign="center"
                    >
                      {modalConfig?.loadingPaymentSubtitle}
                    </Text>
                    <Link
                      fontSize="sm"
                      mb="20px"
                      color="blue.500"
                      href={`https://etherscan.io/tx/${pendingHash}`}
                      textAlign="center"
                      target="_blank"
                    >
                      {modalConfig?.loadingPaymentButton}
                    </Link>
                  </>
                )}
              {successData?.status === "confirmed" && (
                <>
                  <Icon
                    as={CiCircleCheck}
                    boxSize="90px"
                    mb="25px"
                    color="#09d809"
                  />
                  <Text
                    fontSize="lg"
                    mb="20px"
                    fontWeight="bold"
                    textAlign="center"
                  >
                    {modalConfig?.confirmedPaymentTitle}

                    {/* {amount}{" "}
                    {platform === "Questron" ? "ACE" : "DAPPX"} */}
                  </Text>
                  <Text
                    fontSize="sm"
                    mb="20px"
                    color="gray.500"
                    textAlign="center"
                  >
                    {modalConfig?.confirmedPaymentSubtitle}
                  </Text>
                  <Link
                    fontSize="sm"
                    mb="20px"
                    color="blue.500"
                    href={`https://etherscan.io/tx/${pendingHash}`}
                    textAlign="center"
                    target="_blank"
                  >
                    {modalConfig?.confirmedPaymentButton}
                  </Link>
                  <Button
                    mt={4}
                    colorScheme="teal"
                    type="submit"
                    width={"60%"}
                    bg="#09d809"
                    height={"40px"}
                    color="white"
                    fontSize="md"
                    onClick={() => confirmedModalClose()}
                    _hover={{ bg: "#09d809" }}
                    _active={{ bg: "#09d809" }}
                    _focus={{ bg: "#09d809" }}
                  >
                    {loading ? <Spinner /> : t("OK")}
                  </Button>
                </>
              )}
              {successData?.status === "failed" && (
                <>
                  <Icon
                    as={CiCircleRemove}
                    boxSize="90px"
                    mb="25px"
                    color="red.500"
                  />
                  <Text
                    fontSize="lg"
                    mb="20px"
                    fontWeight="bold"
                    textAlign="center"
                  >
                    {modalConfig?.failedPaymentTitle}

                    {/* {amount}{" "}
                    {platform === "Questron" ? "ACE" : "DAPPX"} */}
                  </Text>
                  <Text
                    fontSize="sm"
                    mb="20px"
                    color="gray.500"
                    textAlign="center"
                  >
                    {modalConfig?.failedPaymentSubtitle}
                  </Text>
                  <Link
                    fontSize="sm"
                    mb="20px"
                    color="blue.500"
                    href={`https://etherscan.io/tx/${pendingHash}`}
                    textAlign="center"
                    target="_blank"
                  >
                    {modalConfig?.failedPaymentButton}
                  </Link>
                  <Button
                    mt={4}
                    colorScheme="teal"
                    type="submit"
                    width={"60%"}
                    bg="#09d809"
                    height={"40px"}
                    color="white"
                    fontSize="md"
                    onClick={() => confirmedModalClose()}
                    _hover={{ bg: "#09d809" }}
                    _active={{ bg: "#09d809" }}
                    _focus={{ bg: "#09d809" }}
                  >
                    {loading ? <Spinner /> : t("OK")}
                  </Button>
                </>
              )}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={depositModal}
        onClose={() => setDepositModal(false)}
        isCentered
        width="800px"
        maxWidth="800px"
        className="deposit chakra-modal__content-container"
        id="deposit chakra-modal__content-container"
      >
        <ModalOverlay />
        <ModalContent
          backgroundColor="white"
          borderRadius="20px"
          width="800px"
          maxWidth="800px"
        >
          <ModalBody p={"16px"} borderRadius={"16px"} width="800px">
            <Box display="flex" flexWrap={"wrap"}>
              <Box w="48%">
                <Box className="flex flex-col space-y-4 w-[320px] pr-3 border-r border-gray-300">
                  <Heading as="h2" size="lg" mb="16px" fontWeight="semibold">
                    {title}
                  </Heading>
                  {platform === "Questron" && (
                    <Text fontSize="lg" mb="16px">
                      {depositType == "pledging"
                        ? pledgingDescription
                        : stakingDescription}
                    </Text>
                  )}
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        depositType == "pledging"
                          ? pledgingBulletPoints
                          : stakingBulletPoints,
                    }}
                    className="list-align-dev"
                  />
                </Box>
              </Box>
              <Box w="52%" pl="10px" borderLeft={"1px solid #ddd"}>
                <Box
                  className="stakeCardSection"
                  bg="linear-gradient(65.21deg, rgb(55, 57, 74) 19.1%, rgb(62, 75, 79) 100%)"
                  borderRadius="20px"
                  p="16px 0 0 0"
                >
                  <Box p="0px 16px 0 16px">
                    <Flex justify="space-between" align="center">
                      <Box>
                        <Text
                          fontSize="xl"
                          color="gray.300"
                          className="chat-active"
                          align={"center"}
                          justifyContent={"center"}
                        >
                          Available {platform === "Questron" ? "ACE" : "DAPPX"}
                        </Text>
                        <Text fontSize="xl" fontWeight="bold" color="white">
                          {walletBalance}{" "}
                          {platform === "Questron" ? " ACE" : " DAPPX"}
                        </Text>
                      </Box>
                      <Button
                        _hover={{ bg: "rgba(0, 0, 0, .2)" }}
                        _active={{ bg: "rgba(0, 0, 0, .2)" }}
                        _focus={{ bg: "rgba(0, 0, 0, .2)" }}
                        bg="rgba(0, 0, 0, .2)"
                        color={"#fff"}
                        fontSize={"14px"}
                        p="4px 4px 4px 10px"
                        display="flex"
                        alignItems={"center"}
                        gap="5px"
                      >
                        {walletAddressFormat(authState?.user?.metamaskAddress)}{" "}
                        <Icon
                          viewBox="0 0 24 24"
                          boxSize={6}
                          style={{ borderRadius: "100%" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            x={0}
                            y={0}
                            height={24}
                            width={24}
                          >
                            <rect
                              x={0}
                              y={0}
                              rx={0}
                              ry={0}
                              height={24}
                              width={24}
                              transform="translate(-3.975973212625078 3.7885736767548837) rotate(166.5 12 12)"
                              fill="#f17c02"
                            />
                            <rect
                              x={0}
                              y={0}
                              rx={0}
                              ry={0}
                              height={24}
                              width={24}
                              transform="translate(10.732919221512088 -4.162495628237221) rotate(494.5 12 12)"
                              fill="#15b8f2"
                            />
                            <rect
                              x={0}
                              y={0}
                              rx={0}
                              ry={0}
                              height={24}
                              width={24}
                              transform="translate(-3.987759890586655 17.169357837131557) rotate(222.1 12 12)"
                              fill="#fc5300"
                            />
                            <rect
                              x={0}
                              y={0}
                              rx={0}
                              ry={0}
                              height={24}
                              width={24}
                              transform="translate(-10.78330584781217 21.462487604857618) rotate(185.6 12 12)"
                              fill="#018c7d"
                            />
                          </svg>
                        </Icon>
                      </Button>
                    </Flex>
                  </Box>
                  <Box mt="27px" p="16px">
                    <form autoComplete="off">
                      {platform === "Questron" ? (
                        <>
                          <Text
                            fontSize="5xl"
                            fontWeight="bold"
                            color="white"
                            textAlign={"center"}
                          >
                            {amount}{" "}
                            {platform === "Questron" ? " ACE" : " DAPPX"}
                          </Text>
                          <Box mt="20px">
                            <Flex justifyContent={"center"} mb="16px">
                              <Text
                                fontSize="xs"
                                color="rgba(255, 255, 255, .8)"
                              >
                                {pledgingReceivingLabel}
                              </Text>
                            </Flex>
                          </Box>
                        </>
                      ) : (
                        <RadioGroup
                          onChange={(e) => {
                            setDepositType(e);
                          }}
                        >
                          <Stack
                            direction="column"
                            spacing={5}
                            // maxH={"40vh"}
                            // overflow={"scroll"}
                          >
                            <Box
                              key={"pledging"}
                              style={{
                                padding: "0.6rem",
                                borderRadius: "10px",
                              }}
                              border={"1px solid #fff"}
                              bg={
                                depositType === "pledging"
                                  ? buttonColor
                                  : "transparent"
                              }
                              _hover={{ bg: buttonHoverColor }}
                              _active={{ bg: buttonHoverColor }}
                            >
                              <Radio value={"pledging"}>
                                <VStack align="start" spacing={1}>
                                  <Text
                                    fontSize="md"
                                    fontWeight="bold"
                                    color="white"
                                    textAlign={"center"}
                                  >
                                    {pledgingDescription}
                                  </Text>
                                  <Text
                                    fontSize="3xl"
                                    fontWeight="bold"
                                    color="white"
                                    textAlign={"center"}
                                  >
                                    {stakingConfig?.pledgingAmount}{" "}
                                    {platform === "Questron"
                                      ? " ACE"
                                      : " DAPPX"}
                                  </Text>
                                  <Box mt="20px">
                                    <Flex justifyContent={"center"} mb="16px">
                                      <Text
                                        fontSize="xs"
                                        color="rgba(255, 255, 255, .8)"
                                      >
                                        {isKor
                                          ? modalConfig?.receivingLabelKr
                                          : modalConfig?.receivingLabel}
                                      </Text>
                                    </Flex>
                                  </Box>
                                </VStack>
                              </Radio>
                            </Box>

                            <Box
                              key={"staking"}
                              style={{
                                padding: "0.6rem",
                                borderRadius: "10px",
                              }}
                              border={"1px solid #fff"}
                              bg={
                                depositType === "staking"
                                  ? buttonColor
                                  : "transparent"
                              }
                              _hover={{ bg: buttonHoverColor }}
                              _active={{ bg: buttonHoverColor }}
                            >
                              <Radio value={"staking"}>
                                <VStack align="start" spacing={1}>
                                  <Text
                                    fontSize="md"
                                    fontWeight="bold"
                                    color="white"
                                    textAlign={"center"}
                                  >
                                    {stakingDescription}
                                  </Text>
                                  <Text
                                    fontSize="3xl"
                                    fontWeight="bold"
                                    color="white"
                                    textAlign={"center"}
                                  >
                                    {stakingConfig?.stakingAmount}
                                    {platform === "Questron"
                                      ? " ACE"
                                      : " DAPPX"}
                                  </Text>
                                  <Box mt="20px">
                                    <Flex justifyContent={"center"} mb="16px">
                                      <Text
                                        fontSize="xs"
                                        color="rgba(255, 255, 255, .8)"
                                      >
                                        {" "}
                                        {isKor
                                          ? modalConfig?.stakingReceivingLabelKr
                                          : modalConfig?.stakingReceivingLabel}
                                      </Text>
                                    </Flex>
                                  </Box>
                                </VStack>
                              </Radio>
                            </Box>
                          </Stack>
                        </RadioGroup>
                      )}

                      <Button
                        mt={4}
                        colorScheme="teal"
                        isFullWidth
                        bg={buttonColor}
                        fontSize="xl"
                        height={"56px"}
                        onClick={createTransaction}
                        _hover={{ bg: buttonHoverColor }}
                        _active={{ bg: buttonHoverColor }}
                        _focus={{ bg: buttonHoverColor }}
                      >
                        {loading ? <Spinner /> : t("Deposit")}
                      </Button>
                      <Button
                        mt={4}
                        colorScheme="teal"
                        isFullWidth
                        bg="#EDF2F7"
                        height={"50px"}
                        color="#6D4C77"
                        fontSize="md"
                        onClick={() => setDepositModal(false)}
                        _hover={{ bg: "#dcddde" }}
                        _active={{ bg: "#dcddde" }}
                        _focus={{ bg: "#dcddde" }}
                      >
                        Cancel
                      </Button>
                    </form>
                  </Box>
                </Box>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>

      {authState?.user?.membership !== "pro" && !buttonHide && (
        <Button
          fontSize="sm"
          ml="20px"
          h="35px"
          alignItems={"center"}
          borderRadius={metrics === "chatpage" ? "30px" : "10px"}
          bg={buttonColor}
          color={"#fff"}
          fontWeight="700"
          onClick={() => {
            setDepositModal(true);
            getContractInfo();
          }}
          disabled={loading}
          _hover={{ bg: buttonColor }}
          _active={{ bg: buttonColor }}
          _focus={{ bg: buttonColor }}
          textTransform={"uppercase"}
        >
          {platform === "Questron"
            ? "Activate Questron"
            : `JOIN ${configData?.appName} PRO`}
        </Button>
      )}
    </>
  );
}

export default DepositButton;
