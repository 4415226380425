// Chakra imports
import {
  Button,
  Flex,
  LightMode,
  Text,
  useColorModeValue,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Heading,
  Link,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "contexts/ConfigContext";
import { AuthContext } from "contexts/AuthContext";
import SubscriptionCancellation from "./SubscriptionCancellation";
import { ModalContext } from "contexts/ModalContext";
import { BsInfinity } from "react-icons/bs";
import { userPrivateRequest } from "config/axios.config";
import moment from "moment";

const ManageSubscription = ({ info }) => {
  const modalData = useContext(ModalContext);

  return (
    <Box ml="10px">
      <Link
        href={"#"}
        onClick={(e) => {
          e.preventDefault();
          modalData.setCurrentModalName("membershipPackageModal");
        }}
        textDecoration={"underline"}
        target="_blank"
      >
        Manage Your Subscription
      </Link>
    </Box>
  );
};

export default function Subscriptions({ info }) {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";

  const authData = useContext(AuthContext);
  const configData = useContext(ConfigContext);
  const config = configData.config || {};
  const membership = authData?.authState?.membership;
  const platform = process.env?.REACT_APP_PLATFORM;
  const { setModal } = authData;

  const [subscriptions, setSubscriptions] = useState([]);

  let creditLabel =
    configData?.config?.global?.CREDIT_CONFIGURATION?.value?.creditLabel ??
    "CHI";
  const { t } = useTranslation();

  const fetchSubscriptions = async () => {
    userPrivateRequest
      .get("/subscription-list?status=active")
      .then((response) => {
        console.log("subscriptions");

        setSubscriptions(response?.data?.data ?? []);
      })
      .catch((error) => {
        console.log("error", error.message);
      });
  };

  useEffect(() => {
    fetchSubscriptions();
  }, []);
  return (
    <Card mt={"20px"}>
      <Text
        fontSize="xl"
        color={textColorPrimary}
        mb="20px"
        ms="10px"
        fontWeight="bold"
      >
        {t("YourPlan")}
      </Text>

      {info?.membership === "pro" && (
        <>
          <Flex justifyContent={"space-between"}>
            <Box>
              <Text
                fontSize="lg"
                color={textColorPrimary}
                fontWeight="bold"
                mb="10px"
                ms="10px"
              >
                {platform === "Chingu" && (
                  <>
                    {info?.subscriptionPlan?.name
                      ? info?.subscriptionPlan?.name
                      : t("ChinguPro2")}
                  </>
                )}
                {platform === "Questron" && t("QuestronPro2")}
                {platform === "DappStoreAi" && t("DappxPro2")}
              </Text>
              <Text
                fontSize="lg"
                color={textColorPrimary}
                fontWeight="bold"
                mb="10px"
                ms="10px"
                display={"flex"}
                alignItems={"center"}
                flexWrap="nowrap"
                gap={"5px"}
              >
                {info?.subscriptionPlan?.unlimitedCredit === "yes" ? (
                  <BsInfinity color={textColorPrimary} mr={"5px"} />
                ) : (
                  info?.creditBalance?.toLocaleString()
                )}{" "}
                {creditLabel}
              </Text>
              {info?.creditBalance <= 0 && (
                <Text
                  fontSize="lg"
                  color={"red"}
                  fontWeight="bold"
                  mb="10px"
                  ms="10px"
                >
                  {"Insufficient " + creditLabel}
                </Text>
              )}
            </Box>

            <Box textAlign={"right"}>
              {configData?.config?.global?.WEB3_AUTHENTICATION?.value !==
                "YES" && (
                <>
                  <ManageSubscription />
                  <SubscriptionCancellation />
                </>
              )}
            </Box>
          </Flex>
        </>
      )}

      {info?.membership === "free" &&
        info?.subscriptionStatus === "unconfirmedPayment" && (
          <>
            <Heading mb="15px" fontSize={"24px"} color="green">
              Subscription in progress.
            </Heading>
          </>
        )}
      {info?.membership === "free" &&
        info?.subscriptionStatus !== "unconfirmedPayment" && (
          <>
            {platform === "Chingu" && (
              <Heading mb="15px" fontSize={"24px"}>
                {t("ChinguFree")}
              </Heading>
            )}

            {platform === "Questron" && (
              <Heading mb="15px" fontSize={"24px"}>
                {t("QuestronFree")}
              </Heading>
            )}

            {platform === "DappStoreAi" && (
              <Heading mb="15px" fontSize={"24px"}>
                {t("DappxFree")}
              </Heading>
            )}

            <Box textAlign={"left"}>
              {platform === "Chingu" && (
                <button
                  textDecoration={"underline"}
                  className="underline"
                  onClick={() => {
                    setModal(true);
                  }}
                >
                  {t("SubscribetoChinguPro")}
                </button>
              )}
            </Box>
          </>
        )}

      {platform == "Chingu" && (
        <Box width="100%" p={4}>
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th textAlign="left" fontSize={"md"}>
                    Plan
                  </Th>
                  <Th textAlign="center" fontSize={"md"}>
                    CHI
                  </Th>
                  <Th textAlign="right" fontSize={"md"}>
                    Next Renewal Date
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {subscriptions.map((row, index) => (
                  <Tr key={index}>
                    <Td textAlign="left">
                      <Text fontSize="md">
                        {row?.subscriptionPlan?.category?.nameEn ??
                          "Pro Access"}
                      </Text>
                    </Td>
                    <Td textAlign="center">
                      <Text fontSize="md">
                        {row.currentCycleBalance?.toLocaleString()}{" "}
                        {creditLabel}
                      </Text>
                    </Td>
                    <Td textAlign="right">
                      <Text fontSize="md">
                        {moment
                          .utc(row.subscriptionStartedAt)
                          .add(row.totalCycle, "month")
                          .format("MM/DD/YYYY HH:mm A")
                          .toString()}
                      </Text>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Card>
  );
}
