import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  SimpleGrid,
  Badge,
  VStack,
  Spinner,
  useColorModeValue,
  HStack,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { userPrivateRequest } from "config/axios.config";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ConfigContext } from "contexts/ConfigContext";
import { AuthContext } from "contexts/AuthContext";
import { toast } from "react-toastify";
import { ModalContext } from "contexts/ModalContext";
import Select from "react-select";
import usePaddle from "hooks/usePaddle";
import { motion } from "framer-motion"; // Import framer-motion
import useCustomHistory from "langHoc/useCustomHistory";

const DropdownSelect = ({
  options,
  setSelectedPrice,
  selectedPrice,
  intervalType,
}) => {
  const configData = useContext(ConfigContext);
  const authData = useContext(AuthContext);

  const creditLabel =
    configData?.config?.global?.CREDIT_CONFIGURATION?.value?.creditLabel ??
    "CHI";
  const user = authData?.authState?.user ?? {};

  const primaryColor = useColorModeValue(
    configData?.config?.global?.COLORS?.value?.primaryColor,
    configData?.config?.global?.COLORS?.value?.primaryDarkColor
  );
  const textColor = useColorModeValue(
    configData?.config?.global?.COLORS?.value?.textColor,
    configData?.config?.global?.COLORS?.value?.textDarkColor
  );
  const bgColor = useColorModeValue(
    configData?.config?.global?.COLORS?.value?.bgColor,
    configData?.config?.global?.COLORS?.value?.bgDarkColor
  );
  const selectRef = useRef(null);

  useEffect(() => {
    const selectInput = selectRef.current?.select?.inputRef;
    if (selectInput) {
      selectInput.setAttribute("readonly", "true");
    }
  }, []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "30px",
      height: "30px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center", // Center horizontally
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: "8px",
      paddingRight: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center", // Center horizontally
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      justifyContent: "center", // Center horizontally
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      justifyContent: "center", // Center horizontally
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      justifyContent: "center", // Center horizontally
    }),
    option: (styles, { isDisabled, isSelected, isFocused }) => ({
      ...styles,
      paddingLeft: "8px",
      paddingRight: "45px",
      backgroundColor: isDisabled
        ? "#cccccc"
        : isSelected
        ? bgColor
        : isFocused
        ? bgColor
        : "white",
      color: isDisabled
        ? textColor
        : isSelected
        ? primaryColor
        : isFocused
        ? "black"
        : "black",
      cursor: isDisabled ? "not-allowed" : "default",
      ":active": {
        ...styles[":active"],
        backgroundColor: isSelected ? bgColor : bgColor,
        color: "white",
      },
      "& input": {
        opacity: 0,
        height: 0,
        color: "transparent",
      },
      ":hover": {
        color: primaryColor,
        backgroundColor: "white",
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "center", // Center horizontally
    }),
  };

  const selectOptions = options.map((option) => {
    const creditAmount =
      intervalType === "MONTHLY" ? option.monthlyCredit : option.yearlyCredit;

    const formattedCreditAmount = new Intl.NumberFormat("en-US").format(
      creditAmount
    );
    let isDisabled =
      Number(option?.monthlyPrice ?? 0) <=
        Number(user.subscriptionPlan?.monthlyPrice ?? 0) &&
      user?.subscriptionPlan?.category === option?.category
        ? true
        : false;
    if (
      user?.subscriptionPlan?.unlimitedCredit === "yes" &&
      option?.unlimitedCredit !== "yes"
    ) {
      isDisabled = true;
    }
    return {
      value: option._id,
      label: (
        <Box
          display={"flex"}
          flexDirection="row"
          gap={2}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box marginLeft={5}>
            {option?.unlimitedCredit === "yes"
              ? "Unlimited"
              : formattedCreditAmount + " " + creditLabel}
          </Box>
          {/* {user?.subscriptionPlan?._id === option?._id && (
            <Box
              colorScheme="white"
              bg="#FFBF00"
              fontSize="12px"
              px="2"
              py="1"
              rounded="full"
              color={"#333"}
            >
              ACTIVE
            </Box>
          )} */}
        </Box>
      ),

      // isDisabled: isDisabled,
      isDisabled: false,
    };
  });

  const handleChange = (selectedOption) => {
    const fullOption = options.find(
      (option) => option._id === selectedOption.value
    );
    setSelectedPrice(fullOption);
  };

  return (
    <Select
      ref={selectRef}
      styles={customStyles}
      options={selectOptions}
      onChange={handleChange}
      value={selectOptions.find(
        (option) => option.value === selectedPrice?._id
      )}
      isOptionDisabled={(option) => option.isDisabled}
      classNamePrefix="select"
    />
  );
};

const SegmentedControl = ({ intervalType, setIntervalType }) => {
  let configData = useContext(ConfigContext);

  const selectedTextColor = "white"; // Set selected text color to white
  const selectedBgColor = "#834af4"; // Set selected background color to #834af4
  const unselectedTextColor = "#834af4"; // Set unselected text color to #834af4
  const switchBgColor = "#ffffff"; // Set the background color of the switch

  return (
    <Box
      display="inline-block"
      position="relative"
      borderRadius="full"
      bg={switchBgColor}
      p="4px"
      width="240px"
      height="40px"
    >
      <Box
        position="absolute"
        top="4px"
        left={intervalType === "MONTHLY" ? "4px" : "calc(100% - 4px - 116px)"}
        width="116px"
        height="32px"
        bg={selectedBgColor}
        borderRadius="full"
        transition="left 0.3s"
        zIndex="0"
      />
      {["MONTHLY", "YEARLY"].map((option, index) => (
        <Button
          key={option}
          onClick={() => setIntervalType(option)}
          position="relative"
          bg="transparent"
          color={
            intervalType === option ? selectedTextColor : unselectedTextColor
          }
          width="50%"
          height="100%"
          fontSize="sm"
          borderRadius="full"
          _hover={{ bg: "transparent" }} // Remove hover design
          _active={{ bg: "transparent" }} // Remove active design
          boxShadow="none"
          zIndex="1" // Ensure the button text is above the sliding background
        >
          {option}
        </Button>
      ))}
    </Box>
  );
};

const ToggleControl = ({ options, selectedOption, onChange }) => {
  const bg = useColorModeValue("#1C1C1F", "#1C1C1F");
  const selectedBg = useColorModeValue("white", "#1C1C1F");
  const selectedColor = useColorModeValue("gray.700", "white");
  const color = useColorModeValue("white", "gray.400");

  return (
    <HStack spacing={0}>
      {options.map((option) => (
        <Box
          key={option}
          onClick={() => onChange(option)}
          bg={selectedOption === option ? selectedBg : bg}
          color={selectedOption === option ? selectedColor : color}
          px="16px"
          py="8px"
          borderWidth={0.5}
          borderColor="rgba(255, 255, 255, 0.12)"
          _hover={{ bg: selectedBg, color: selectedColor }}
          _active={{ bg: selectedBg, color: selectedColor }}
          _focus={{ boxShadow: "none" }}
          cursor={"pointer"}
          rounded="xl"
        >
          {option}
        </Box>
      ))}
    </HStack>
  );
};

const PricingCard = ({
  plan,
  intervalType,
  setSelectedPlan,
  setPaymentStep,
  modal,
  setModal,
  stripeSubscriptionHandler,
  paypalSubscriptionHandler,
  paddleSubscriptionHandler,
}) => {
  const paddle = usePaddle();
  const [model, setModel] = useState("GPT 3.5");
  const cardBg = useColorModeValue("#ffffff", "#ffffff"); // Change background to white
  const authData = useContext(AuthContext);
  const modalData = useContext(ModalContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  let configData = useContext(ConfigContext);
  const COLORS = configData?.config?.global?.COLORS?.value;
  const PAYMENT_CHOOSE_CONFIGURATION =
    configData?.config?.global?.PAYMENT_CHOOSE_CONFIGURATION?.value || {};
  const primaryColor = useColorModeValue(
    COLORS.primaryColor,
    COLORS.primaryDarkColor
  );
  const secondaryColor = useColorModeValue(
    COLORS.secondaryColor,
    COLORS.secondaryDarkColor
  );
  const bgColor = "#ffffff"; // Change background color to #834af4
  const borderColor = "#ffffff"; // Change border color to #834af4
  const textColor = "#000000"; // Change text color to black for contrast on white background

  let user = authData?.authState?.user ?? {};

  let creditLabel =
    configData?.config?.global?.CREDIT_CONFIGURATION?.value?.creditLabel ??
    "CHI";

  const freeModeCredit =
    configData?.config?.global?.CREDIT_CONFIGURATION?.value?.freeModeCredit ||
    0;
  const lang = useLocation();
  const isKor = lang?.pathname.includes("/ko");

  const handleModelChange = (newModel) => {
    setModel(newModel);
  };

  const handleFreeModeRequest = async () => {
    setIsSubmitting(true);
    const result = await userPrivateRequest
      .get("/profile/requestForFreeMode")
      .then((response) => {
        setModal(false);
        authData.profileFetch();
        setIsSubmitting(false);
        return response.data;
      })
      .catch((err) => {
        console.error(err?.response?.data?.message);
        setIsSubmitting(false);

        toast(err?.response?.data?.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  let purchasableList = plan?.prices?.filter((price) => {
    if (
      user?.subscriptionPlan?.unlimitedCredit === "yes" &&
      price?.unlimitedCredit !== "yes"
    ) {
      return false;
    }
    if (user?.subscriptionPlan?.category !== price?.category) {
      return true;
    }
    return (
      Number(price?.monthlyPrice ?? 0) >
      Number(user.subscriptionPlan?.monthlyPrice ?? 0)
    );
  });
  const [selectedPrice, setSelectedPrice] = useState(
    purchasableList?.length > 0 ? purchasableList[0] : {}
  );

  const yearlyDiscount = parseInt(
    ((Number(selectedPrice?.monthlyPrice) -
      Number(selectedPrice?.yearlyPrice)) /
      Number(selectedPrice?.monthlyPrice)) *
      100
  );

  const stripeTitle = isKor
    ? PAYMENT_CHOOSE_CONFIGURATION?.stripeTitleKr
    : PAYMENT_CHOOSE_CONFIGURATION?.stripeTitleEn;
  const paypalTitle = isKor
    ? PAYMENT_CHOOSE_CONFIGURATION?.paypalTitleKr
    : PAYMENT_CHOOSE_CONFIGURATION?.paypalTitleEn;

  const hardCodedMonthlyDiscount = {
    "65de9ae86d5d79ea4b2e9b0c": {
      monthlyDiscountInPercent: 0,
      monthlyBasePrice: 0,
      type: "standard",
    },
    "66a90e6ec46bbee301be4f41": {
      monthlyDiscountInPercent: 2,
      monthlyBasePrice: 34,
      type: "standard",
    },
    "65de9b926d5d79ea4b2e9b50": {
      monthlyDiscountInPercent: 0,
      monthlyBasePrice: 0,
      type: "pro",
    },
    "66a90eeec46bbee301be4f89": {
      monthlyDiscountInPercent: 4,
      monthlyBasePrice: 38,
      type: "pro",
    },
    "66a90f7cc46bbee301be4fd9": {
      monthlyDiscountInPercent: 6,
      monthlyBasePrice: 73,
      type: "pro",
    },
  };

  return (
    <Box
      bg={cardBg}
      p={4}
      rounded="2xl"
      shadow="lg"
      textAlign="center"
      position="relative"
      overflow="hidden"
      borderWidth={0.5}
      borderColor={borderColor}
      boxShadow="md"
      minWidth="280px"
      flex="1"
      mb={{ base: 4, md: 0 }} // Add margin-bottom on mobile for spacing between cards
    >
      <Box flex justifyContent={"center"}>
        <Box
          color={textColor}
          fontSize={{ base: "xl", xl: "2xl" }}
          letterSpacing={1}
          whiteSpace={"nowrap"}
        >
          {isKor ? plan?.nameKo : plan?.nameEn}
        </Box>

        <Text fontSize="lg" color={textColor}>
          {isKor ? plan?.subTitleKo : plan?.subTitleEn}
        </Text>

        <Flex alignItems="center">
          <Box w="32%" display={"flex"} justifyContent={"flex-end"}>
            {intervalType !== "MONTHLY" &&
              selectedPrice.monthlyPrice !== selectedPrice.yearlyPrice && (
                <Text color={textColor} textDecoration="line-through">
                  ${selectedPrice?.monthlyPrice ?? 0}
                </Text>
              )}
            {intervalType === "MONTHLY" &&
              Number(
                hardCodedMonthlyDiscount[selectedPrice._id]?.monthlyBasePrice ??
                  0
              ) > 0 && (
                <Text color={textColor} textDecoration="line-through">
                  $
                  {hardCodedMonthlyDiscount[selectedPrice._id].monthlyBasePrice}
                </Text>
              )}
          </Box>

          <Box w="35%" display={"flex"} justifyContent={"center"} mx="1">
            <Text fontSize="4xl" color={textColor} whiteSpace={"nowrap"}>
              $
              {plan?.planType == "free" || plan?.planType == "custom"
                ? Number(plan?.otherPackagePrice ?? 0)
                : intervalType === "MONTHLY"
                ? selectedPrice?.monthlyPrice
                : selectedPrice?.yearlyPrice}
            </Text>
          </Box>

          <Box w="32%" display={"flex"} justifyContent={"flex-start"}>
            {!isNaN(yearlyDiscount) &&
              yearlyDiscount > 0 &&
              intervalType === "YEARLY" && (
                <Badge
                  colorScheme="green"
                  bg="#E5FFB2"
                  fontSize="lg"
                  rounded="full"
                >
                  -{yearlyDiscount}%
                </Badge>
              )}

            {Number(
              hardCodedMonthlyDiscount[selectedPrice._id]
                ?.monthlyDiscountInPercent ?? 0
            ) > 0 &&
              plan.planType !== "custom" &&
              intervalType === "MONTHLY" && (
                <Badge
                  colorScheme="green"
                  bg="#E5FFB2"
                  fontSize="lg"
                  rounded="full"
                >
                  -
                  {
                    hardCodedMonthlyDiscount[selectedPrice._id]
                      ?.monthlyDiscountInPercent
                  }
                  %
                </Badge>
              )}
          </Box>
        </Flex>

        <Text color={textColor} fontSize={"md"} mb="2">
          per month
        </Text>
      </Box>

      <Box
        bg={primaryColor}
        rounded="2xl"
        p="4"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        mb="12px"
      >
        <Text color={"white"} fontSize="md" mb="4" letterSpacing={"2px"}>
          {isKor ? plan?.actionLabelKo : plan?.actionLabelEn}
        </Text>

        {plan.planType === "custom" ? (
          <Box>
            <Text color={"white"} fontSize={"md"}>
              {plan.otherPackageMetaDescriptionEn}
            </Text>
          </Box>
        ) : (
          <Box>
            {plan.planType !== "free" ? (
              <DropdownSelect
                options={plan.prices}
                selectedPrice={selectedPrice}
                setSelectedPrice={setSelectedPrice}
                intervalType={intervalType}
              />
            ) : (
              `${freeModeCredit} ${creditLabel}`
            )}
          </Box>
        )}

        <Tooltip
          label={"Currently, we are not accepting new subscriptions."}
          // shouldWrapChildren
        >
          <Button
            mt={4}
            width="full"
            p="8px"
            boxShadow="md"
            rounded={"xl"}
            cursor={"pointer"}
            border="none"
            bg="#4fdabc" // Main color when not hovered
            color="white"
            transition="background-color 0.3s ease-in-out, color 0.3s ease-in-out"
            _hover={{ bg: "#3ad6b4", color: "#ffffff" }} // Color change on hover
            _active={{ bg: "#3ad6b4", color: "#ffffff" }} // Color change when active
            // disabled={
            //   plan?.planType === "regular" &&
            //   Object.keys(selectedPrice).length <= 0
            // }

            disabled={true}
            onClick={(e) => {
              e.preventDefault();
              if (plan?.planType === "free") {
                handleFreeModeRequest();
              } else if (plan?.planType === "custom") {
                let redirectionUrl = plan?.customPlanRedirectionUrl ?? "#";
                window.open(redirectionUrl, "_blank");
              } else {
                if (Object.keys(selectedPrice).length <= 0) {
                  toast("No price selected", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                  return;
                } else {
                  paddleSubscriptionHandler(selectedPrice);
                  return;
                }
              }
            }}
          >
            {plan?.planType === "free" && isSubmitting ? (
              <>
                <Spinner size="lg" />
              </>
            ) : (
              <Text fontSize="md" fontWeight={"bold"}>
                {isKor ? plan?.buttonTextKo : plan?.buttonTextEn}
              </Text>
            )}
          </Button>
        </Tooltip>
      </Box>

      <Flex
        alignItems="center"
        justifyContent="center"
        width="full"
        mb="8px"
        mt="8px"
      >
        <Box flex="1" height="1px" bg="gray.400" />
        <Text mx={4} color={textColor} textAlign="center" fontSize={"lg"}>
          {isKor ? plan.descriptionLabelKo : plan.descriptionLabelEn}
        </Text>
        <Box flex="1" height="1px" bg="gray.400" />
      </Flex>

      <div
        style={{ color: textColor, textAlign: "left" }}
        dangerouslySetInnerHTML={{
          __html: isKor ? plan?.descriptionKo : plan?.descriptionEn,
        }}
      />
    </Box>
  );
};

const PricingSection = ({
  paymentStep,
  setPaymentStep,
  selectedPlan,
  setSelectedPlan,
  modal,
  setModal,
  intervalType,
  setIntervalType,
  stripeSubscriptionHandler,
  paypalSubscriptionHandler,
  paddleSubscriptionHandler,
}) => {
  const history = useCustomHistory();
  const [isSmallTeamYearly, setIsSmallTeamYearly] = useState(false);
  const lang = useLocation();
  const isKor = lang?.pathname.includes("/ko");
  const handleSmallTeamToggle = () => {
    setIsSmallTeamYearly(!isSmallTeamYearly);
  };
  let configData = useContext(ConfigContext);
  const modalData = useContext(ModalContext);
  const { dispatch } = useContext(AuthContext);
  const COLORS = configData?.config?.global?.COLORS?.value;
  const primaryColor = useColorModeValue(
    COLORS.primaryColor,
    COLORS.primaryDarkColor
  );
  const secondaryColor = useColorModeValue(
    COLORS.secondaryColor,
    COLORS.secondaryDarkColor
  );
  const bgColor = "#f2f2f2"; // Change modal background color to #834af4
  const borderColor = "#f2f2f2"; // Change modal border color to #834af4
  const textColor = "#000000"; // Change text color to white

  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      userPrivateRequest.get("/get-subscription-plans").then((response) => {
        setPlans(response?.data?.data ?? []);
        setLoading(false); // Set loading to false after data is fetched
      });
    } catch (err) {
      setLoading(false); // Set loading to false in case of error
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const PLAN_STATIC_CONFIGURATION =
    configData?.config?.global?.PLAN_STATIC_CONFIGURATION?.value || {};

  let modalTitle = isKor
    ? PLAN_STATIC_CONFIGURATION?.modalTitleKo
    : PLAN_STATIC_CONFIGURATION?.modalTitleEn;
  let modalDescription = isKor
    ? PLAN_STATIC_CONFIGURATION?.modalDescriptionKo
    : PLAN_STATIC_CONFIGURATION?.modalDescriptionEn;

  let modalSubTitle = isKor
    ? PLAN_STATIC_CONFIGURATION?.modalSubTitleKo
    : PLAN_STATIC_CONFIGURATION?.modalSubTitleEn;

  const animationVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.9 },
  };

  const handleSignOut = () => {
    dispatch({ type: "LOGOUT" });
    setModal(false);
    modalData.setCurrentModalName(null);
    let redirection = isKor ? "/ko/auth/sign-in" : "/en/auth/sign-in";
    history.push(redirection);
  };

  return (
    <Flex
      position="fixed"
      top="0"
      left="0"
      width="100vw"
      height="100vh"
      alignItems="center"
      justifyContent="center"
      zIndex="9999"
      backgroundColor="rgba(0, 0, 0, 0.5)" // Optional: for a dark overlay background
    >
      {loading ? (
        <Spinner size="lg" color="white" /> // Decreased the size of the Spinner
      ) : (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={animationVariants}
          transition={{ duration: 0.3 }}
          style={{
            maxWidth: "95vw",
            maxHeight: "90vh",
            width: "90vw",
            height: "90vh",
            border: `1px solid ${borderColor}`,
            borderRadius: "12px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            overflow: "auto",
            backgroundColor: bgColor,
            padding: "24px",
            position: "relative",
          }}
        >
          <IconButton
            aria-label="Close modal"
            icon={<CloseIcon />}
            onClick={(e) => {
              e.preventDefault();
              setModal(false);
              modalData.setCurrentModalName(null);
            }}
            position="absolute"
            top="16px" // Adjusted for equal spacing
            right="16px" // Adjusted for equal spacing
            bg="transparent"
            color={textColor}
            _hover={{ bg: "transparent", color: "#560ee4" }}
            //boxSize="40px" // Adjust this value to change the size of the button
          />

          <Button
            onClick={handleSignOut}
            position="absolute"
            top="16px"
            right="60px" // Adjusted for spacing between close icon and sign out button
            bg="transparent"
            color={textColor}
            _hover={{ bg: "transparent", color: "#560ee4" }}
          >
            Sign Out
          </Button>
          <VStack spacing={3} textAlign="center" mt={"2rem"}>
            <Heading as="h1" size="xl" fontWeight="bold" color={textColor}>
              {modalTitle}
            </Heading>
            <Text fontSize="lg" color={textColor}>
              {modalSubTitle}
            </Text>
            <SegmentedControl
              intervalType={intervalType}
              setIntervalType={setIntervalType}
            />
            <Text fontSize="lg" color={textColor} mt="2">
              <div
                dangerouslySetInnerHTML={{
                  __html: modalDescription,
                }}
              />
            </Text>
          </VStack>

          <Box mt={2}>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={3}>
              {plans.map((plan, index) => (
                <PricingCard
                  key={index}
                  plan={plan}
                  isSmallTeamYearly={isSmallTeamYearly}
                  onSmallTeamToggle={handleSmallTeamToggle}
                  intervalType={intervalType}
                  paymentStep={paymentStep}
                  setPaymentStep={setPaymentStep}
                  selectedPlan={selectedPlan}
                  setSelectedPlan={setSelectedPlan}
                  modal={modal}
                  setModal={setModal}
                  stripeSubscriptionHandler={stripeSubscriptionHandler}
                  paypalSubscriptionHandler={paypalSubscriptionHandler}
                  paddleSubscriptionHandler={paddleSubscriptionHandler}
                />
              ))}
            </SimpleGrid>
          </Box>
        </motion.div>
      )}
    </Flex>
  );
};

export default PricingSection;
