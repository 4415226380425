import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { userPrivateRequest } from "config/axios.config";
import { AuthContext } from "contexts/AuthContext";
import { ConfigContext } from "contexts/ConfigContext";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";

export default function WithdrawModal({
  user,
  stakeInformation,
  isOpen,
  onOpen,
  onClose,
  fetchStakeInformation,
}) {
  const lang = useLocation();
  const configData = useContext(ConfigContext);
  const authData = useContext(AuthContext);
  const config = configData.config || {};
  const isKor = lang?.pathname.includes("/ko");
  const [submitLoading, setSubmitLoading] = useState(false);
  const { t } = useTranslation();

  const colors = config?.global?.COLORS?.value ?? {};
  const primaryColor = useColorModeValue(
    colors?.primaryColor ? colors?.primaryColor : "navy.700",
    colors?.primaryDarkColor ? colors?.primaryDarkColor : "white"
  );
  const primaryHoverColor = useColorModeValue(
    colors?.primaryHoverColor ? colors?.primaryHoverColor : "navy.700",
    colors?.primaryDarkHoverColor ? colors?.primaryDarkHoverColor : "white"
  );
  // let creditLabel =
  //   config?.global?.CREDIT_CONFIGURATION?.value?.creditLabel ?? "CHI";

  const handleWithdrawSubmit = async () => {
    setSubmitLoading(true);
    let data = userPrivateRequest
      .post(`/staking/submitWithdraw`)
      .then((response) => {
        authData.profileFetch();
        onClose();

        fetchStakeInformation();
        return response.data;
      })
      .catch((error) => {
        let message = error.response?.data?.message ?? "Something went wrong";
        const toastId = "withdraw-error-toast";
        if (!toast.isActive(toastId)) {
          toast.error(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            toastId: toastId,
          });
        }
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        width="800px"
        maxWidth="800px"
        className="deposit chakra-modal__content-container"
        id="deposit chakra-modal__content-container"
      >
        <ModalOverlay />
        <ModalContent
          backgroundColor="white"
          borderRadius="20px"
          width="500px"
          maxWidth="600px"
          // height={"350px"}
          p={"16px"}
        >
          <ModalHeader>Withdrawal Preview</ModalHeader>
          <ModalBody borderRadius={"16px"}>
            {/* <Text>lorem ipsusfg sgsdg sgsdgs </Text> */}
            <VStack spacing={4} align="stretch">
              <Box>
                <Text fontWeight="bold">Amount:</Text>
                <Text>
                  {Number(stakeInformation?.withdrawableAmount).toFixed(8)}{" "}
                  {process.env.REACT_APP_PLATFORM === "Questron"
                    ? "ACE"
                    : "DAPPX"}
                </Text>
              </Box>
              {/* <Box>
                        <Text fontWeight="bold">Cycle Completed:</Text>
                        <Text>{true ? "Yes" : "No"}</Text>
                      </Box> */}
              <Box>
                <Text fontWeight="bold">Destination Address:</Text>
                <Text>{user?.metamaskAddress}</Text>
              </Box>
              {
                Number(stakeInformation?.rewardData?.totalReward??0) >
                  Number(stakeInformation?.rewardData?.withdrawableReward??0) && (
                  <Alert status={"error"}>
                    <AlertIcon />
                    <Box flex="1">
                      <AlertTitle>Loss:</AlertTitle>
                      <AlertDescription>
                        You are losing{" "}
                        {stakeInformation?.rewardData?.totalReward -
                          stakeInformation?.rewardData?.withdrawableReward}{" "}
                        {process.env.REACT_APP_PLATFORM === "Questron"
                          ? "ACE"
                          : "DAPPX"}
                        . You have not completed the current cycle of{" "}
                        {stakeInformation?.daysInCycle} days.
                      </AlertDescription>
                    </Box>
                  </Alert>
                )}
            </VStack>
          </ModalBody>{" "}
          <ModalFooter>
            <Flex
              justifyContent={{ base: "center", md: "flex-end" }}
              width="100%"
              gap={"20px"}
            >
              <Button
                variant="brand"
                w="183px"
                fontSize="sm"
                fontWeight="500"
                onClick={(e) => {
                  e.preventDefault();
                  onClose();
                }}
                bg={primaryColor}
                _hover={{
                  bg: primaryHoverColor,
                }}
                _active={{
                  bg: primaryHoverColor,
                }}
                _focus={{
                  bg: primaryHoverColor,
                }}
              >
                {t("Close")}
              </Button>
              <Button
                variant="brand"
                w="183px"
                fontSize="sm"
                fontWeight="500"
                onClick={handleWithdrawSubmit}
                bg={primaryColor}
                _hover={{
                  bg: primaryHoverColor,
                }}
                _active={{
                  bg: primaryHoverColor,
                }}
                _focus={{
                  bg: primaryHoverColor,
                }}
              >
                {submitLoading ? <Spinner /> : t("Confirm")}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
