import {
  Box,
  Button,
  Flex,
  FormLabel,
  Grid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { userPrivateRequest } from "config/axios.config";
import moment from "moment/moment";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ChatPromtPagination from "components/ChatPromtPagination";
import WithdrawModal from "./WithdrawModal";
import { ConfigContext } from "contexts/ConfigContext";
import CircularProgress from "components/charts/CircularProgress";

export default function StakingReport({
  user,
  stakeInformation,
  fetchStakeInformation,
}) {
  const lang = useLocation();
  const isKor = lang?.pathname.includes("/ko");
  const { t } = useTranslation();
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const [rewards, setRewards] = useState([]);
  const limit = 10;
  const [page, setPage] = useState(1);
  const [totalRow, setTotalRow] = useState(0);

  let configData = useContext(ConfigContext);
  const config = configData?.config;

  const colors = config?.global?.COLORS?.value ?? {};
  const primaryColor = useColorModeValue(
    colors?.primaryColor ? colors?.primaryColor : "navy.700",
    colors?.primaryDarkColor ? colors?.primaryDarkColor : "white"
  );
  const primaryHoverColor = useColorModeValue(
    colors?.primaryHoverColor ? colors?.primaryHoverColor : "navy.700",
    colors?.primaryDarkHoverColor ? colors?.primaryDarkHoverColor : "white"
  );
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");

  const { isOpen, onOpen, onClose } = useDisclosure();
  let creditLabel =
    config?.global?.CREDIT_CONFIGURATION?.value?.creditLabel ?? "CHI";
  const SI = config?.global?.STAKING_INFORMATION?.value;
  const cardTitle = isKor ? SI?.pledgingOverviewKr : SI?.pledgingOverview;
  const staked = isKor ? SI?.stakedKr : SI?.staked;
  const daysRemaining = isKor ? SI?.daysRemainingKr : SI?.daysRemaining;
  const withdraw = isKor ? SI?.unstakeKr : SI?.unstake;

  const fetchRewardHistories = async () => {
    await userPrivateRequest
      .get(`/credit/histories?status=active&type=credit&limit=${limit}&page=${page}`)
      .then((response) => {
        setRewards(response?.data?.data?.docs ?? []);
        setTotalRow(response?.data?.data?.totalDocs ?? 0);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  useEffect(() => {
    fetchRewardHistories();
  }, [page]);
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  return (
    <>
      <Card mt={"20px"}>
        <Flex flexDirection={"row"} justifyContent={"space-between"}>
          <Text
            fontSize="xl"
            color={textColorPrimary}
            mb="20px"
            ms="10px"
            fontWeight="bold"
          >
            {cardTitle}
          </Text>

          <Tooltip
            label={
              stakeInformation?.hasPendingWithdraw
                ? "A withdrawal is being processed. All withdrawals require 48 business hours for security and verification purposes."
                : Number(stakeInformation?.unStakingAvailableInDays ?? 0) > 0
                ? `${stakeInformation?.unStakingAvailableInDays} days remaining until withdrawal is allowed.`
                : ""
            }
            shouldWrapChildren
          >
            <Button
              variant="brand"
              w="183px"
              fontSize="sm"
              fontWeight="500"
              bg={primaryColor}
              _hover={{
                bg: primaryHoverColor,
              }}
              _active={{
                bg: primaryHoverColor,
              }}
              _focus={{
                bg: primaryHoverColor,
              }}
              onClick={() => {
                onOpen();
              }}
              _disabled={{
                bg: "#ececec",
                cursor: "not-allowed",
              }}
              disabled={
                stakeInformation?.hasPendingWithdraw ||
                Number(stakeInformation?.unStakingAvailableInDays ?? 0) > 0
              }
            >
              {/* {withdraw} */}

              {t("Withdrawal")}
            </Button>
          </Tooltip>
        </Flex>
        <>
          <Grid
            bg={cardColor}
            p="15px"
            px="10px"
            mt="auto"
            mb="10px"
            templateColumns="repeat(2, 1fr)"
            gap={2}
          >
            <Card direction="column" py="5px" textAlign={"center"}>
              <Text
                fontSize="md"
                color="secondaryGray.600"
                fontWeight="700"
                mb="5px"
              >
                Deposit Amount
              </Text>
              <Text fontSize="lg" color={textColor} fontWeight="700">
                {Number(stakeInformation?.totalStaked ?? 0)}{" "}
                {process.env.REACT_APP_PLATFORM === "Questron"
                  ? "ACE"
                  : "DAPPX"}
              </Text>
            </Card>

            <Card direction="column" py="5px" textAlign={"center"}>
              <Text
                fontSize="md"
                color="secondaryGray.600"
                fontWeight="700"
                mb="3px"
              >
                {daysRemaining}
              </Text>
              <Box mx="auto" mb="10px" mt="10px">
                {/* <CircularProgress
                  percentage={parseInt(
                    ((stakeInformation?.lockedPeriodDays -
                      stakeInformation?.unStakingAvailableInDays) /
                      stakeInformation?.lockedPeriodDays) *
                      100
                  )}
                /> */}
                <Text fontSize="lg" color={textColor} fontWeight="700">
                  {stakeInformation?.unStakingAvailableInDays}{" "}
                  {stakeInformation?.unStakingAvailableInDays > 1
                    ? "Days"
                    : "Day"}
                </Text>
              </Box>
            </Card>
          </Grid>

          {/* Table for Staking History */}
          <Flex direction="column" mb="25px">
            <Box overflowX="auto">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th
                      textAlign="center"
                      style={{ textTransform: "none" }}
                      fontSize="md"
                    >
                      {capitalizeFirstLetter(t("Date"))}
                    </Th>
                    <Th
                      textAlign="center"
                      style={{ textTransform: "none" }}
                      fontSize="md"
                    >
                      {capitalizeFirstLetter(t("Type"))}
                    </Th>
                    <Th
                      textAlign="center"
                      style={{ textTransform: "none" }}
                      fontSize="md"
                    >
                      {capitalizeFirstLetter(t("Currency"))}
                    </Th>
                    <Th
                      textAlign="center"
                      style={{ textTransform: "none" }}
                      fontSize="md"
                    >
                      {capitalizeFirstLetter(t("Amount"))}
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {rewards?.map((reward, index) => (
                    <Tr key={index}>
                      <Td textAlign="center" style={{ fontSize: "small" }}>
                        {moment(reward.createdAt).format("YYYY/MM/DD HH:mm")}
                      </Td>
                      <Td textAlign="center" style={{ fontSize: "small" }}>
                        {[
                          "proSubscription",
                          "StakingSubscription",
                          "PledgingSubscription",
                        ].indexOf(reward.source) !== -1
                          ? "Deposit"
                          : "Airdrop"}
                      </Td>
                      <Td textAlign="center" style={{ fontSize: "small" }}>
                        {creditLabel}
                      </Td>
                      <Td textAlign="center" style={{ fontSize: "small" }}>
                        {Number(reward?.amount).toLocaleString()}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              <ChatPromtPagination
                limit={limit}
                page={page}
                totalRow={totalRow}
                handlePageChange={(limit, newOffset, page) => {
                  setPage(page);
                }}
              />

              <WithdrawModal
                user={user}
                stakeInformation={stakeInformation}
                fetchStakeInformation={fetchStakeInformation}
                onClose={onClose}
                onOpen={onOpen}
                isOpen={isOpen}
              />
            </Box>
          </Flex>
        </>
      </Card>
    </>
  );
}
