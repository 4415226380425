// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  InputGroup,
  InputRightElement,
  Input,
  SimpleGrid,
  Spinner,
  Text,
  useColorModeValue,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormLabel,
  Link,
  TableContainer,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import InputField from "components/fields/InputField";
import { userPrivateRequest } from "config/axios.config";
import useCustomHistory from "langHoc/useCustomHistory";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "contexts/ConfigContext";
import { AuthContext } from "contexts/AuthContext";
import { walletAddressFormat } from "utils/utils";
import { CopyIcon } from "@chakra-ui/icons";
import StakingReport from "./StakingReport";
import TopUpCredit from "./TopUpCredit";
import PaypalSubscriptionPaymentStatusModal from "views/admin/modals/PaypalSubscriptionPaymentStatusModal";
import { BsInfinity, BsWindows } from "react-icons/bs";
import { FaApple } from "react-icons/fa";
import SubscriptionCancellation from "./SubscriptionCancellation";
import { ModalContext } from "contexts/ModalContext";
import Password from "./Password";
import Subscriptions from "./Subscriptions";
import StakingReward from "./StakingReward";

export default function Settings({
  info,
  data,
  setData,
  handleSubmit,
  submitLoading,
  setSubmitLoading,
}) {
  // Chakra Color Mode
  const { t } = useTranslation();
  const history = useCustomHistory();
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";
  const { isOpen, onOpen, onClose } = useDisclosure();

  const authData = useContext(AuthContext);
  const configData = useContext(ConfigContext);
  const config = configData.config || {};
  const membership = authData?.authState?.membership;
  const { setModal } = authData;
  const [loading, setLoading] = useState(true);

  const [aggrement, setAggrement] = useState();
  const [changed, setChanged] = useState(false);
  const [initData, setInitData] = useState({});

  const colors = config?.global?.COLORS?.value ?? {};
  const profilePageConfiguration =
    config?.global?.PROFILE_PAGE_CONFIGURATION?.value ?? {};
  const primaryColor = useColorModeValue(
    colors?.primaryColor ? colors?.primaryColor : "navy.700",
    colors?.primaryDarkColor ? colors?.primaryDarkColor : "white"
  );
  const primaryHoverColor = useColorModeValue(
    colors?.primaryHoverColor ? colors?.primaryHoverColor : "navy.700",
    colors?.primaryDarkHoverColor ? colors?.primaryDarkHoverColor : "white"
  );
  const secondaryColor = useColorModeValue(
    colors?.secondaryColor ? colors?.secondaryColor : "navy.700",
    colors?.secondaryDarkColor ? colors?.secondaryDarkColor : "white"
  );
  const secondaryHoverColor = useColorModeValue(
    colors?.secondaryHoverColor ? colors?.secondaryHoverColor : "navy.700",
    colors?.secondaryDarkHoverColor ? colors?.secondaryDarkHoverColor : "white"
  );

  const bgColor = useColorModeValue(
    colors?.bgColor ? colors?.bgColor : "navy.700",
    colors?.bgDarkColor ? colors?.bgDarkColor : "white"
  );

  const changeForm = (event) => {
    let updated = {
      ...data,
      [event.target.name]: event.target.value,
    };
    setChanged(JSON.stringify(initData) !== JSON.stringify(updated));
    setData(updated);
  };

  useEffect(() => {
    setData({
      email: info?.email,
      firstName: info?.firstName,
      lastName: info?.lastName,
      username: info?.username,
      photo: info?.photo,
      metamaskAddress: info?.metamaskAddress,
    });
    setInitData({
      email: info?.email,
      firstName: info?.firstName,
      lastName: info?.lastName,
      username: info?.username,
      photo: info?.photo,
      metamaskAddress: info?.metamaskAddress,
    });
  }, [info]);

  // const fetchInfo = async () => {
  //   try {
  //     const result = await userPrivateRequest.get("/get-agreement");
  //     setAggrement(result?.data?.aggrement);
  //   } catch (err) {}
  // };
  // useEffect(() => {
  //   if (authData) {
  //     fetchInfo();
  //   }
  // }, [authData]);

  const platform = process.env?.REACT_APP_PLATFORM;

  let creditLabel =
    configData?.config?.global?.CREDIT_CONFIGURATION?.value?.creditLabel ??
    "CHI";

  const [stakeInformation, setStakeInformation] = useState({});
  const fetchStakeInformation = async () => {
    const result = await userPrivateRequest
      .get("/staking/stakingOverview")
      .then((response) => {
        setStakeInformation(response?.data?.data ?? {});
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    fetchStakeInformation();
  }, []);
  return (
    <>
      {Object.keys(info).length > 0 && (
        <FormControl>
          <Card>
            <Flex direction="column" mb="20px" ms="10px">
              <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                {t("AccountSetting")}
              </Text>
            </Flex>
            {configData?.config?.global?.WEB3_AUTHENTICATION?.value ===
              "YES" && (
              <SimpleGrid columns={{ sm: 1 }}>
                <FormLabel
                  display="flex"
                  ms="10px"
                  fontSize="sm"
                  color={"#1b2559"}
                  fontWeight="bold"
                  _hover={{ cursor: "pointer" }}
                >
                  Wallet Address
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    pr="4.5rem"
                    pt="20px"
                    pb="20px"
                    type="text"
                    fontWeight="500"
                    variant="main"
                    isReadOnly
                    _readOnly={{
                      borderColor: "#E0E5F2",
                      borderRadius: "xl",
                    }}
                    mb="25px"
                    id="metamaskAddress"
                    label={t("WalletAddress")}
                    placeholder={t("WalletAddress")}
                    onChange={changeForm}
                    value={walletAddressFormat(data.metamaskAddress)}
                    name="metamaskAddress"
                    disabled={true}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      _hover={{ bg: "#fafafa" }}
                      onClick={() => {
                        navigator.clipboard.writeText(data.metamaskAddress);
                      }}
                    >
                      <CopyIcon cursor={"pointer"} color="black" />
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {profilePageConfiguration?.email !== "hide" && (
                  <InputField
                    mb="25px"
                    id="email"
                    label={t("EmailAddress")}
                    placeholder={t("EmailAddress")}
                    onChange={changeForm}
                    value={data.email}
                    name="email"
                    disabled={
                      profilePageConfiguration?.email == "readonly"
                        ? true
                        : false
                    }
                  />
                )}
                <SimpleGrid
                  columns={{ sm: 1 }}
                  spacing={{ base: "20px", xl: "20px" }}
                >
                  <InputField
                    mb="25px"
                    id="username"
                    label={t("Username")}
                    placeholder={t("Username")}
                    onChange={changeForm}
                    value={data?.username}
                    name="username"
                    disabled={false}
                  />
                </SimpleGrid>
              </SimpleGrid>
            )}

            {configData?.config?.global?.WEB3_AUTHENTICATION?.value ===
              "NO" && (
              <>
                {profilePageConfiguration?.email !== "hide" && (
                  <InputField
                    mb="25px"
                    id="email"
                    label={t("EmailAddress")}
                    placeholder={t("EmailAddress")}
                    onChange={changeForm}
                    value={data.email}
                    name="email"
                    disabled={
                      profilePageConfiguration?.email == "readonly"
                        ? true
                        : false
                    }
                  />
                )}

                <SimpleGrid
                  columns={{ sm: 1 }}
                  spacing={{ base: "20px", xl: "20px" }}
                >
                  <InputField
                    mb="25px"
                    id="username"
                    label={t("Username")}
                    placeholder={t("Username")}
                    onChange={changeForm}
                    value={data?.username}
                    name="username"
                    disabled={false}
                  />
                </SimpleGrid>
                <SimpleGrid
                  columns={{ sm: 1, md: 2 }}
                  spacing={{ base: "20px", xl: "20px" }}
                >
                  <InputField
                    mb="25px"
                    me="30px"
                    id="first_name"
                    label={t("FirstName")}
                    placeholder={t("FirstName")}
                    onChange={changeForm}
                    value={data.firstName}
                    name="firstName"
                  />
                  <InputField
                    mb="25px"
                    id="last_name"
                    label={t("LastName")}
                    placeholder={t("LastName")}
                    onChange={changeForm}
                    value={data.lastName}
                    name={"lastName"}
                  />
                </SimpleGrid>
              </>
            )}

            <Flex
              justifyContent={{ base: "center", md: "flex-end" }}
              width="100%"
              gap={"20px"}
            >
              <Button
                variant="brand"
                w="183px"
                fontSize="sm"
                fontWeight="500"
                onClick={() => {
                  handleSubmit();
                }}
                bg={primaryColor}
                _hover={{
                  bg: primaryHoverColor,
                }}
                _active={{
                  bg: primaryHoverColor,
                }}
                _focus={{
                  bg: primaryHoverColor,
                }}
                disabled={!changed}
              >
                {submitLoading ? <Spinner /> : t("Save")}
              </Button>
              <Button
                variant="brand"
                w="183px"
                fontSize="sm"
                fontWeight="500"
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/chat");
                }}
                bg={primaryColor}
                _hover={{
                  bg: primaryHoverColor,
                }}
                _active={{
                  bg: primaryHoverColor,
                }}
                _focus={{
                  bg: primaryHoverColor,
                }}
              >
                {t("Close")}
              </Button>
            </Flex>
          </Card>

          <Subscriptions info={info} />

          {profilePageConfiguration?.passwordModification && (
            <Password
              mt={"20px"}
              primaryColor={primaryColor}
              primaryHoverColor={primaryHoverColor}
            />
          )}

          {profilePageConfiguration?.agiDownload && (
            <Card mt={"20px"}>
              <Flex direction="column" mb="20px" ms="10px">
                <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                  {platform} AGI PC App
                </Text>
                <Text fontSize="md" color={textColorSecondary}>
                  Download and install the AGI on your PC . Alllow the AGI to
                  perform human grade labor using your PC.
                </Text>
              </Flex>

              <Flex alignItems="center" gap="20px" ms="10px">
                <Button
                  as="a"
                  href={profilePageConfiguration?.agiDownload}
                  target="_blank"
                  leftIcon={<BsWindows />}
                >
                  Windows
                </Button>
                <Button leftIcon={<FaApple />} disabled>
                  Coming Soon
                </Button>
              </Flex>
            </Card>
          )}

          {/* {info?.membership === "pro" &&
            configData?.config?.global?.WEB3_AUTHENTICATION?.value !==
              "YES" && <TopUpCredit />} */}

          {info?.membership === "pro" &&
            configData?.config?.global?.WEB3_AUTHENTICATION?.value === "YES" &&
            stakeInformation?.type == "pledging" && (
              <StakingReport
                user={info}
                stakeInformation={stakeInformation}
                fetchStakeInformation={fetchStakeInformation}
              />
            )}
          {info?.membership === "pro" &&
            platform === "DappStoreAi" &&
            stakeInformation?.type == "staking" && (
              <StakingReward
                user={info}
                stakeInformation={stakeInformation}
                fetchStakeInformation={fetchStakeInformation}
              />
            )}
        </FormControl>
      )}
    </>
  );
}
